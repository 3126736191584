<template>
  <el-card v-loading="tableLoading" class="box-card">
    <div class="title">
      <Header />
    </div>

    <div class="setting-detail">{{translateTitle('设置详情')}}</div>
    <el-row :gutter="40">
      <!-- <el-col :span="12"
        ><div class="detail-item">
          <div class="two-between">
            <span class="title">{{translateTitle('电话号码')}}</span>
            <el-switch
              v-model="openPhoneValid"
              active-color="#13ce66"
              inactive-color="#444"
              :disabled="userInfo.phone?false:true"
              @change="changeOpenValid(2)"
            >
            </el-switch>
          </div>
          <div class="tel">
            {{ userInfo.phoneCountryCode }}&nbsp;{{ userInfo.phone }}
          </div>
          <div class="two-between">
            <div v-if="userInfo.phone">{{translateTitle('已绑定')}}</div>
            <div @click="handleClick(2)" v-else>{{translateTitle('还未绑定')}}，<span class="common-color">{{translateTitle('去绑定')}}</span></div>
            <div @click="handleClick(2)">
              <img width="25" src="../../assets/imgs/ico_bianji@2x.png" />
            </div>
          </div>
        </div></el-col> -->
      <el-col :span="12">
        <div class="detail-item">
          <div class="two-between">
            <span class="title">{{translateTitle('安全码设置')}}</span>
            <el-switch
              v-model="openSecurityCodeValid"
              active-color="#13ce66"
              inactive-color="#444"
              :disabled="userInfo.hasSecurityCode?false:true"
              @change="changeOpenValid(3)"
            >
            </el-switch>
          </div>
          <div class="tel">******</div>
          <div class="two-between">
            <div v-if="userInfo.hasSecurityCode">{{translateTitle('已绑定')}}</div>
            <div @click="handleClick(3)" v-else>{{translateTitle('还未绑定')}}，<span class="common-color">{{translateTitle('去绑定')}}</span></div>
            <!-- <img :src="right" v-if="userInfo.hasSecurityCode" />
            <img :src="error" v-else /> -->
            <div @click="handleClick(3)">
              <img width="25" src="../../assets/imgs/ico_bianji@2x.png" />
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="detail-item">
          <div class="two-between">
            <span class="title">{{translateTitle('邮箱验证')}}</span>
            <!-- <el-switch
              v-model="openEmailValid"
              active-color="#13ce66"
              inactive-color="#444"
              :disabled="userInfo.email?false:true"
              @change="changeOpenValid(1)"
            >
            </el-switch> -->
          </div>
          <div class="tel">{{ userInfo.email }}</div>
          <div class="two-between">
            <div v-if="userInfo.email">{{translateTitle('已绑定')}}</div>
            <div @click="handleClick(1)" v-else>{{translateTitle('还未绑定')}}，<span class="common-color">{{translateTitle('去绑定')}}</span></div>
            <!-- <img :src="right" v-if="userInfo.email" />
            <img :src="error" v-else /> -->
            <div @click="handleClick(1)">
              <img width="25" src="../../assets/imgs/ico_bianji@2x.png" />
            </div>
          </div>
        </div>
      </el-col>
      
    </el-row>
    <el-row :gutter="40">
      
      <el-col :span="12">
        <div class="detail-item">
          <div class="two-between ">
            <span class="title">{{translateTitle('谷歌验证器')}}</span>
            <!-- <el-switch
              v-model="openGoogleValid"
              active-color="#13ce66"
              inactive-color="#444"
              :disabled="openGoogleValid?false:true"
              @change="changeOpenValid(4)"
            >
            </el-switch> -->
          </div>
          <div class="tel transparent">111</div>
          <div class="two-between">
            <div v-if="userInfo.hasGoogleAuth">{{translateTitle('已绑定')}}</div>
            <div v-else>{{translateTitle('还未绑定')}}，<span class="common-color">{{translateTitle('去绑定')}}</span></div>
            <!-- <img :src="right" v-if="openGoogleValid" />
            <img :src="error" v-else /> -->
            <div @click="handleClick(4)">
              <img width="25" src="../../assets/imgs/ico_bianji@2x.png" />
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="detail-item">
          <div class="two-between">
            <span class="title">{{translateTitle('登录密码')}}</span>
          </div>
          <div class="tel">**********</div>
          <div class="two-between">
            <div></div>
            <!-- <img :src="right" v-if="userInfo.email" />
            <img :src="error" v-else /> -->
            <div @click="handleClick(5)">
              <!-- <i class="el-icon-edit"></i><span class="edit">{{translateTitle('编辑')}}</span> -->
              <img width="25" src="../../assets/imgs/ico_bianji@2x.png" />
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 绑定邮箱/手机号/安全码设置 -->
    <DialogPop
      width="600px"
      :title="bindingTitle"
      :visible="visible"
      :closeBtn="false"
       :sureText="translateTitle('确定')"
      :btnloading="btnloading"
      @onClose="onClose"
      @onSure="onSure('ruleForm')"
    >
      <div class="change-form">
        <el-form
          label-position="top"
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          hide-required-asterisk
        >
          <!-- 绑定邮箱 -->
          <div v-if="bindingType == 1">
            <el-form-item :label="translateTitle('输入邮箱')" prop="email">
              <el-input
                v-model="ruleForm.email"
                clearable
                :placeholder="translateTitle('请输入邮箱账户')"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="userInfo.email" :label="translateTitle('输入安全码')" prop="securityCode">
              <el-input
                v-model="ruleForm.securityCode"
                clearable
                :placeholder="translateTitle('请输入6位数字')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="translateTitle('安全验证')" prop="emailValidateCode">
              <div class="tow-input">
                <el-input
                  v-model="ruleForm.emailValidateCode"
                  clearable
                  :placeholder="translateTitle('请输入邮箱验证码')"
                ></el-input>
                <el-button
                  class="send-code"
                  :disabled="timeNum > 0"
                  :loading="loading"
                  :class="timeNum > 0? 'btn-disabled':''"
                  type="text"
                  @click="handleGetCode('email')"
                >
                   {{ timeNum > 0 ? `${translateTitle('重新发送')}(${timeNum}s)` : translateTitle("发送验证码") }}
                </el-button>
              </div>
            </el-form-item>
          </div>

          <!-- 绑定手机号 -->
          <div v-if="bindingType == 2">
            <el-form-item :label="translateTitle('输入电话号码')" prop="phone">
              <div class="tow-input1">
                <el-select
                  class="svg-container"
                  v-model="ruleForm.phoneCountryCode"
                  filterable
                  clearable
                  :placeholder="translateTitle('输入国籍')"
                  @change="changePhoneCode"
                >
                  <template #prefix>
                    <span class="flex">
                      <img v-if="nationalityLogo" :src="nationalityLogo" width="24" height="24" />
                    </span>
                  </template>
                  <el-option
                    v-for="item in options"
                    :key="item.phoneCode"
                    :label="item.phoneCode"
                    :value="item.phoneCode"
                  >
                    <div class="disal">
                    <img
                      :src="item.logo"
                      class="mr10"
                      width="24"
                      height="24"
                    />
                    <span class="select-item">{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
                <el-input
                  v-model="ruleForm.phone"
                  clearable
                  :placeholder="translateTitle('请输入电话号码')"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item v-if="userInfo.phone" :label="translateTitle('输入安全码')" prop="securityCode">
              <el-input
                v-model="ruleForm.securityCode"
                clearable
                :placeholder="translateTitle('请输入6位数字')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="translateTitle('安全验证')" prop="phoneValidateCode">
              <div class="tow-input">
                <el-input
                  v-model="ruleForm.phoneValidateCode"
                  clearable
                  :placeholder="translateTitle('请输入验证码')"
                ></el-input>
                <el-button
                  class="send-code"
                  :disabled="timeNum > 0"
                  :loading="loading"
                  type="text"
                  @click="handleGetCode('phone')"
                >
                  {{ timeNum > 0 ? `${translateTitle('重新发送')}(${timeNum}s)` : translateTitle("发送验证码") }}
                </el-button>
              </div>
            </el-form-item>
          </div>

          <!-- 安全码设置 -->
          <div v-if="bindingType == 3">
            <el-form-item :label="translateTitle('输入安全码')" prop="firstSecurityCode">
              <el-input
                v-model="ruleForm.firstSecurityCode"
                clearable
                :placeholder="translateTitle('请输入6位数字')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="translateTitle('重复输入安全码')" prop="secondSecurityCode">
              <el-input
                v-model="ruleForm.secondSecurityCode"
                clearable
                :placeholder="translateTitle('请输入6位数字')"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item :label="translateTitle('安全验证')" prop="validateType">
              <el-radio-group v-model="ruleForm.validateType">
              <el-radio label="email">{{translateTitle("邮箱")}}</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item :label="translateTitle('安全验证')" prop="validateCode">
              <div class="tow-input">
                <el-input
                  v-model="ruleForm.validateCode"
                  clearable
                  :placeholder="translateTitle('请输入验证码')"
                ></el-input>
                <el-button
                  class="send-code"
                  :disabled="timeNum > 0"
                  :loading="loading"
                  type="text"
                  @click="handleGetCode"
                >
                  {{ timeNum > 0 ? `${translateTitle('重新发送')}(${timeNum}s)` : translateTitle("发送验证码") }}
                </el-button>
              </div>
            </el-form-item>
          </div>
          <!-- 修改登录密码 -->
          <div v-if="bindingType == 5">
            <el-form-item :label="translateTitle('请输入新密码')" prop="password">
              <el-input
                v-model="ruleForm.password"
                clearable
                :placeholder="translateTitle('请输入密码')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="translateTitle('请输入邮箱账户')" prop="email">
              <el-input
                v-model="ruleForm.email"
                clearable
                :placeholder="translateTitle('请输入邮箱账户')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="translateTitle('安全验证')" prop="validateCode">
              <div class="tow-input">
                <el-input
                  v-model="ruleForm.validateCode"
                  clearable
                  :placeholder="translateTitle('请输入验证码')"
                ></el-input>
                <el-button
                  class="send-code"
                  :disabled="timeNum > 0"
                  :loading="loading"
                  type="text"
                  @click="handleGetCode('email')"
                >
                  {{ timeNum > 0 ? `${translateTitle('重新发送')}(${timeNum}s)` : translateTitle("发送验证码") }}
                </el-button>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </DialogPop>
    <!-- 绑定谷歌验证器 -->
    <DialogPop
      width="800px"
      :title="bindingTitle"
      :visible="googleVisible"
      @onClose="googleOnClose"
      :btnloading="saveLoading"
       :closeText="googleActive == '1'?'':translateTitle('上一步')"
       :sureText="googleActive == '2'?translateTitle('下一步'):googleActive == '3'?translateTitle('确定'):translateTitle('绑定')"
      @onSure="googleActive == '2'? next(2) :googleActive == '3'?handleSave1('ruleForm1'):next(1)"
    >
      <div v-if="googleActive == '1'" class="change-form mb20">
        <div class="google-box">
          <img width="100" src="../../assets/imgs/pic_download@2x.png" alt="">
          <div>{{translateTitle('请下载并安装谷歌验证器')}}</div>
        </div>
        <div class="google-box google-box1 tl">{{translateTitle('下载Google验证器')}}<img width="20" src="../../assets/imgs/arrow_right@2x.png"></div>
      </div>
      <div v-else-if="googleActive == '2'" class="change-form mb20">
        <div class="two-between mb15">
          <div class="title">{{ translateTitle('绑定验证器说明') }}</div>
          <div class="common-color" @click="next(2)">{{ translateTitle('跳过教程') }}</div>
        </div>
        <el-row :gutter="6">
          <el-col :span="8">
            <div class="grid-content">
              <div class="content1 mb10">01</div>
              <div  class="content2 mb10">{{translateTitle('复制或扫描')}}</div>
              <div class="content3 tc mb15">
                <div>{{ secret || 'UY7659OIKJ7YG5VX'}}</div>
                <div class="common-color mt10" @click="onCopy(secret)">{{translateTitle('复制')}}</div>
              </div>
              <div class="content3 tc"><vue-qr :size="180" :text="googleAuthQrcodeText" /></div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <div class="content1 mb10">02</div>
              <div  class="content2 mb10">{{translateTitle('打开谷歌验证器')}}</div>
              <img class="padding15" src="../../assets/imgs/pic_google@2x.png" alt="">
              </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <div class="content1 mb10">03</div>
              <div  class="content2 mb10">{{translateTitle('返回Bank')}}</div>
              <div class="content2">({{translateTitle('请确保您已完成步骤2')}})</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-else>
      <div class="change-form">
        <div class="two-between mb15">
          <div class="title">{{ translateTitle('安全验证') }}</div>
        </div>
        <el-form
          label-position="top"
          :model="ruleForm1"
          status-icon
          :rules="rules1"
          ref="ruleForm1"
          label-width="100px"
          class="demo-ruleForm"
          hide-required-asterisk
        >
          <el-form-item :label="translateTitle('输入Google验证码')" prop="securityCode">
            <el-input
              v-model="ruleForm1.securityCode"
              clearable
              :placeholder="translateTitle('请输入Google验证码')"
            >
              <template slot="append"><span class="common-color" @click="pasteText">{{translateTitle('粘贴')}}</span></template>
            </el-input>
          </el-form-item>
          <el-form-item
            :label="translateTitle('将发送验证码到您的')+ mobileFilter(userInfo.email)"
            prop="validateCode"
          >
            <div class="tow-input mt10">
              <el-input
                v-model="ruleForm1.validateCode"
                clearable
                :placeholder="translateTitle('请输入邮箱验证码')"
              ></el-input>
              <el-button
                class="send-code"
                :disabled="timeNum > 0"
                  :loading="loading"
                type="text"
                @click="handleGetCode1"
              >
                {{ timeNum > 0 ? `${translateTitle('重新发送')}(${timeNum}s)` : translateTitle("发送验证码") }}
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      </div>
    </DialogPop>
    <!-- 下载谷歌浏览器 -->
    <!-- <DialogPop
      width="500px"
      :title="translateTitle('详情')"
      :visible="visibleDetail"
      :closeBtn="false"
      :sureBtn="false"
      @onClose="onCloseDetail"
    >
      <div class="change-form mb20">
        <div class="content3 tc"><vue-qr :size="180" :text="googleAuthQrcodeText" /></div>
      </div>
    </DialogPop> -->
  </el-card>
</template>

<script>
import Headers from "../../components/accountHeader/index.vue";
import { sendEmailCode, sendPhoneCode } from "@/api/index";
import local from "@/utils/local";
import { getUserInfo, resetPassword } from "@/api/login.js";
import {
  settingResetPhone,
  settingResetSecurityCode,
  settingResetEmail,
  settingBindGoogleAuth,
  settingUpdateGoogleAuth,
  openValid,
  getGoogleAuth,
} from "@/api/account.js";
import { getNational } from "@/api/accountManage";
import VueQr from "vue-qr";
import { translateTitle } from "@/utils/i18n";
export default {
  components: { Headers, VueQr },
  data() {
    //输入验证码
    const validateFirstSecurityCode = (rule, value, callback) => {
      if ("" === value) {
        callback(new Error(this.translateTitle("验证码不能为空")));
      } else if (!/^(-)?[0-9]+([.][0-9]{1,})?$/.test(value)) {
        callback(new Error(this.translateTitle("请输入6位数字")));
      } else if (!/^[0-9]{6}$/.test(value)) {
        callback(new Error(this.translateTitle("请输入6位数字")));
      } else callback();
    };
    //重复输入验证码
    const validateFirstSecondSecurityCode = (rule, value, callback) => {
      if ("" === value) {
        callback(new Error(this.translateTitle("验证码不能为空")));
      } else if (!/^(-)?[0-9]+([.][0-9]{1,})?$/.test(value)) {
        callback(new Error(this.translateTitle("请输入6位数字")));
      } else if (!/^[0-9]{6}$/.test(value)) {
        callback(new Error(this.translateTitle("请输入6位数字")));
      } else if (this.ruleForm.firstSecurityCode != value) {
        callback(new Error(this.translateTitle("两次验证码必须一致")));
      } else callback();
    };
    const validatePassword = (rule, value, callback) => {
      if ("" === value) {
        callback(new Error(this.translateTitle("密码不能为空")));
      } else if(/^\d+$/.test(value)) {
        callback(new Error(this.translateTitle("密码验证")));
      } else if(/^[a-z]+$/i.test(value)) {
        callback(new Error(this.translateTitle("密码验证")));
      } else if(!/^[A-Za-z0-9]+$/.test(value)) {
        callback(new Error(this.translateTitle("密码验证1")));
      } else if(value.length > 18 || value.length < 10) {
        callback(new Error(this.translateTitle("密码长度不能低于10位")));
      } else callback();
    };
    const validatePhone = (rule, value, callback) => {
  var xreg = /^[0-9]*$/
  if (!xreg.test(value)) {
    callback(new Error(this.translateTitle("请输入正确格式")))
  } else {
    callback()
  }
}
    return {
      value1: false,
      right: require("@/assets/imgs/icon_bangding.png"),
      error: require("@/assets/imgs/icon_weibangding.png"),
      visible: false,
      options: [],
      codeText: "",
      saveLoading: false,
      openEmailValid: "",
      openPhoneValid: "",
      openGoogleValid: "",
      openSecurityCodeValid: "",
      ruleForm1: {
        phone: "",
        phoneCountryCode: "",
        phoneValidateCode: "",
        validateType: "email",
        securityCode: "",
      },
      //安全码
      ruleForm: {
        phone: "",
        phoneCountryCode: "",
        firstSecurityCode: "",
        secondSecurityCode: "",
        validateCode: "",
        validateType: "email",
        email: "",
        emailValidateCode: "",
        password: '',
      },
      googleActive: '1',
      rules: {
        pass: [{ required: true, message: "请输入活动名称", trigger: "change" }],
        age: [{ required: true, message: "请输入活动名称", trigger: "change" }],
        firstSecurityCode: [
          {
            required: true,
            trigger: "change",
            validator: validateFirstSecurityCode,
          },
        ],
        secondSecurityCode: [
          {
            required: true,
            trigger: "change",
            validator: validateFirstSecondSecurityCode,
          },
        ],
        validateCode: [
          { required: true, message: this.translateTitle("请输入验证码"), trigger: "change" },
          { required: true, validator: validatePhone, trigger: "change" }
        ],
        password: [
          { required: true, trigger: "change", validator: validatePassword },
        ],
        securityCode: [
          { required: true, message: this.translateTitle("请输入安全验证码"), trigger: "change" },
          { required: true, validator: validateFirstSecurityCode, trigger: "change" }
        ],
        emailValidateCode: [
          { required: true, message: this.translateTitle("请输入邮箱验证码"), trigger: "change" },
          { required: true, validator: validatePhone, trigger: "change" }
        ],
      },
      rules1: {
        securityCode: [
          { required: true, message: this.translateTitle("请输入安全验证码"), trigger: "change" },
          { required: true, validator: validateFirstSecurityCode, trigger: "change" }
        ],
        validateCode: [
          { required: true, message: this.translateTitle("请输入验证码"), trigger: "change" },
          { required: true, validator: validatePhone, trigger: "change" }
        ],
      },
      timer: null,
      timeNum: 0,
      bindingType: 3, //1绑定邮箱,2绑定手机号
      bindingTitle: this.translateTitle("安全码设置"),
      googleVisible: false,
      googleAuthQrcodeText: '',
      secret: '',
      copyImg: require("@/assets/imgs/icon_copy.png"),
      formInline: {
        user: "",
      },
      userInfo: "",
      btnloading: false,
      globalLoading: null,
      loading: false,
      tableLoading: false,
      nationalityLogo: '',
      prew:'1',
    };
  },
  computed: {
    // userInfo() {
    //   return local.get("userInfo");
    // },
  },
  created() {
    this.getData();
  },
  methods: {
    translateTitle,
    mobileFilter(val){
            let reg = /^(.{4}).*(.{6})$/
            return val.replace(reg,'$1....$2')
        },
    async getData() {
      this.globalLoading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
      let res = await getUserInfo();
      if(res.code == 200) {
        this.userInfo = res.data;
        this.openEmailValid = this.userInfo.openEmailValid;
        this.openPhoneValid = this.userInfo.openPhoneValid;
        this.openGoogleValid = this.userInfo.openGoogleValid;
        this.openSecurityCodeValid = this.userInfo.openSecurityCodeValid;
        this.ruleForm.phone = this.userInfo.phone;
        this.ruleForm.email = this.userInfo.email;
        this.ruleForm.phoneCountryCode = this.userInfo.phoneCountryCode;
        this.globalLoading.close()
      }
      
    },
    //编辑安全码,谷歌
    handleClick(type) {
      this.bindingType = type;
      if (type == 1 || type == 2 || type == 3 || type == 5) {
        if (type == 3) {
          this.bindingTitle = this.userInfo.hasSecurityCode
            ? this.translateTitle("修改安全码设置")
            : this.translateTitle("安全码设置");
        } else if(type == 1) {
          this.bindingTitle =
            this.userInfo.email
              ? this.translateTitle("修改邮箱")
              : this.translateTitle("绑定邮箱");
        } else if(type == 2){
          this.getNationalData()
          this.bindingTitle =
            this.userInfo.phone
              ? this.translateTitle("修改电话号码")
              : this.translateTitle("绑定电话号码");
        } else {
           this.bindingTitle = this.translateTitle("修改登录密码")
        }
        this.visible = true;
      } else if (type == 4) {
        this.bindingTitle = this.userInfo.hasGoogleAuth
          ? this.translateTitle("修改谷歌验证器")
          : this.translateTitle("绑定谷歌验证器");
        this.googleVisible = true;
        getGoogleAuth().then(res => {
          this.googleAuthQrcodeText = res.data.googleAuthQrcodeText;
          this.secret = res.data.secret
        })
      }
    },
    bindTel() {
      this.visible = true;
      this.bindingTitle = this.translateTitle("绑定电话号码");
      this.bindingType = 2;
    },
    onClose() {
      this.$refs["ruleForm"].resetFields();
      this.visible = false;
      clearInterval(this.timer);
      this.timeNum = 0;
    },
    onSure(ruleForm) {
      let params;
      let _request;
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          if (this.bindingType == 3) {
            params = {
              firstSecurityCode: this.ruleForm.firstSecurityCode,
              secondSecurityCode: this.ruleForm.secondSecurityCode,
              validateCode: this.ruleForm.validateCode,
              validateType: this.ruleForm.validateType,
            };
            _request = settingResetSecurityCode;
          } else if (this.bindingType == 2) {
            params = {
              phone: this.ruleForm.phone,
              phoneCountryCode: this.ruleForm.phoneCountryCode,
              phoneValidateCode: this.ruleForm.phoneValidateCode,
              securityCode: this.ruleForm.securityCode,
            };
            _request = settingResetPhone;
          } else if (this.bindingType == 1) {
            params = {
              email: this.ruleForm.email,
              emailValidateCode: this.ruleForm.emailValidateCode,
              securityCode: this.ruleForm.securityCode,
            };
            _request = settingResetEmail;
          } else {
            params = {
              email: this.ruleForm.email,
              validateCode: this.ruleForm.validateCode,
              password: this.ruleForm.password,
              validateType: "email",
            };
            _request = resetPassword;
          }

          // this.btnloading = true
          this.globalLoading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
          _request(params).then((res) => {
            if (res.code == 200) {
              this.$message.success(this.translateTitle("操作成功"));
              this.getData();
              this.visible = false;
              this.$refs["ruleForm"].resetFields();
            } else {
                 this.$message.error(this.translateTitle(res.msg));
              }
          }).finally(() => {
            // this.btnloading = false
            this.globalLoading.close()
          });
          // alert("submit!");
        } else {
          return false;
        }
      });
      this.visible = true;
    },
    //安全码
    async handleGetCode(val) {
      if (val == "phone") {
        this.getCode("phone");
      } else if (val == "email") {
        this.getCode("email");
      } else {
        if (!this.ruleForm.validateType) {
          this.$message.warning(this.translateTitle("请选择安全验证方式"));
        } else {
          if (this.ruleForm.validateType == "phone") {
            this.getCode("phone");
          } else if (this.ruleForm.validateType == "email") {
            this.getCode("email");
          }
        }
      }
    },
    //谷歌弹框
    async handleGetCode1() {
      if (!this.ruleForm1.validateType) {
        this.$message.warning(this.translateTitle("请选择安全验证方式"));
      } else {
        if (this.ruleForm1.validateType == "phone") {
          this.getCode("phone");
        } else if (this.ruleForm1.validateType == "email") {
          this.getCode("email");
        }
      }
    },
    //获取验证码
    async getCode(type) {
      if (type == "phone") {
        let res = await sendPhoneCode({
          phone: this.bindingType == 2?this.ruleForm.phone:this.userInfo.phone,
          phoneCountryCode: this.userInfo.phoneCountryCode,
          moduleType: '100'
        });
        if (res.code == 200) {

          this.$message.success(this.translateTitle("已发送请注意查收"));
          this.loading = true
          this.timeNum = 60;
          this.timer = setInterval(() => {
            this.timeNum--;
            if (!this.timeNum) {
              clearInterval(this.timer);
              this.loading = false
            }
          }, 1000);
        } else {
              this.loading = false
          this.$message.error(this.translateTitle(res.msg));
        }
      } else if (type == "email") {
        console.log(1312)
        let res = await sendEmailCode({ email: this.bindingType == 1?this.ruleForm.email:this.userInfo.email, moduleType: this.bindingType == '5'? '1':'20' });
        if (res.code == 200) {
         this.$message.success(this.translateTitle("已发送请注意查收"));
          this.timeNum = 60;
          this.loading = true
          this.timer = setInterval(() => {
            this.timeNum--;
            if (!this.timeNum) {
              clearInterval(this.timer);
              this.loading = false
            }
          }, 1000);
        } else {
            this.loading = false
          this.$message.error(this.translateTitle(res.msg));
        }
      }
    },
    //保存谷歌验证
    handleSave1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.saveLoading = true
          this.globalLoading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
          if (this.userInfo.hasGoogleAuth) {
            //修改
            let params = {
              securityCode: this.ruleForm1.securityCode,
              validateCode: this.ruleForm1.validateCode,
              validateType: this.ruleForm1.validateType,
            };
            // settingUpdateGoogleAuth
            settingBindGoogleAuth(params).then((res) => {
              if (res.code == 200) {
                this.$message.success(this.translateTitle("操作成功"));
                this.codeText = res.data.googleAuthQrcodeText;
                this.googleVisible = false
              } else {
                this.$message.error(this.translateTitle(res.msg));
              }
            }).finally(() => {
              this.saveLoading = false
              this.globalLoading.close()
            });
          } else {
            //绑定
            settingBindGoogleAuth({
              securityCode: this.ruleForm1.securityCode,
              validateCode: this.ruleForm1.validateCode
            }).then((res) => {
              if (res.code == 200) {
                this.$message.success(this.translateTitle("操作成功"));
                this.codeText = res.data.googleAuthQrcodeText;
              this.googleVisible = false
                // console.log("this.---------", this.googleAuthQrcodeText);
                // this.googleOnClose();
              } else {
                this.$message.error(this.translateTitle(res.msg));
              }
            }).finally(() => {
              this.saveLoading = false
              this.globalLoading.close()
            });
          }
        } else {
          return false;
        }
      });
    },
    googleOnClose(val) {
      console.log(val)
      if(val) {
        if(this.prew == '2') {
          this.googleActive --
        } else {
          this.googleActive = 1
        }
      } else {
        this.googleVisible = false;
        this.googleActive = 1
      }
      
      // this.$refs["ruleForm1"].resetFields();
      // this.googleVisible = false;
      
      clearInterval(this.timer);
      this.timeNum = 0;
    },
    next(val) {
      this.prew = val
      this.googleActive++
    },
    changeOpenValid(val) {
      let params;
      if (val == "1") {
        params = {
          openEmailValid: this.openEmailValid,
        };
      } else if (val == "2") {
        params = {
          openPhoneValid: this.openPhoneValid,
        };
      } else if (val == "3") {
        params = {
          openSecurityCodeValid: this.openSecurityCodeValid,
        };
      } else {
        params = {
          openGoogleValid: this.openGoogleValid,
        };
      }
      this.globalLoading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
      openValid(params).then((res) => {
        if (res.code == 200) {
          this.$message.success(this.translateTitle("操作成功"));
        } else {
          this.$message.error(this.translateTitle(res.msg));
        }
      }).finally(()=> {
        this.globalLoading.close()
      });
    },
    onCopy(text) {
      if (navigator.clipboard) {
        // clipboard api 复制
        navigator.clipboard.writeText(text);
      } else {
        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        // 隐藏此输入框
        textarea.style.position = "fixed";
        textarea.style.clip = "rect(0 0 0 0)";
        textarea.style.top = "10px";
        // 赋值
        textarea.value = text;
        // 选中
        textarea.select();
        // 复制
        document.execCommand("copy", true);
        // 移除输入框
        document.body.removeChild(textarea);
      }
      this.$message.success(this.translateTitle("内容已复制到剪贴板"));
    },
    async pasteText() {
      console.log(123423)
      try {
        const text = await navigator.clipboard.readText();
        this.ruleForm1.securityCode = text;
      } catch (err) {
        console.error(err);
      }
    },
    async getNationalData() {
      let res = await getNational();
      this.options = res.data;
      if(this.options) {
          this.changePhoneCode(this.ruleForm.phoneCountryCode)
      }
      // this.changebillingCountry(this.ruleForm.billingCountry)
      // this.changenationality(this.ruleForm.nationality)
      // this.changedeliveryCountry(this.ruleForm.deliveryCountry)
    },
    changePhoneCode(data) {
      let obj  = this.options.find(item => item.phoneCode === data)
      this.nationalityLogo = obj.logo
      // 
      // this.ruleForm.phoneCountryCode = obj.coinName;
    }
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 26px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #31363e;
  margin-bottom: 20px;
  text-align: right;
}

.setting-detail {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #31363e;
  margin-top: 30px;
  margin-bottom: 30px;
}
.detail-item {
  width: 100%;
  // height: 208px;
  background: #F7F6F5;
  border-radius: 10px;
  padding: 30px 30px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .title {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #b9bfca;
  }
  .tel {
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 700;
    color: #24282e;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .edit {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: bold;
    color: #6891a1;
    margin-left: 10px;
    cursor: pointer;
  }
}

.tow-input {
  display: flex;
  ::v-deep {
    .el-input {
      width: 63%;
    }
    .num-input {
      width: 32% !important;
      margin-right: 8%;
    }
  }
  .send-code {
    width: 34%;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #786234;
    background: transparent;
    color: #786234;
    margin-left: 3%;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
  }
}
.tow-input1 {
  display: flex;
  ::v-deep {
    .el-select {
      width: 45%;
      margin-right: 15px;
    }
    .el-input {
      // width: 60%;
    }
    .num-input {
      // width: 32% !important;
      margin-right: 8%;
    }
  }
  .send-code {
    width: 34%;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #165BA4;
    background: transparent;
    color: #165BA4;
    margin-left: 3%;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
  }
}
.google-tips {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #b9bfca;
  text-align: center;
}
.two-between {
  font-size: 14px;
  font-weight: 400;
  color: rgba(49,54,62,0.6);
}
.google-code {
  width: 46%;
  height: 270px;
  background-color: #eae;
  margin-top: 15px;
  margin: 20px (100% - 50%) / 2;
}
.google-address {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #b9bfca;
}
.google-code-num {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #31363e;
}

.change-form {
  .el-row {
    display: flex;
    .el-col-8 {
      flex: 1;
    }
  }
  .google-box {
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    padding: 15px;
    margin-bottom: 15px;
    img {
      margin-bottom: 15px;
    }
  }
  .google-box1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      margin-bottom: 0px;
    }
  }
  .grid-content {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    height: 100%;
    img {
      width: 100%;
    }
    .content1 {
      font-size: 32px;
      font-weight: 700;
      color: rgba(121,127,136,0.4);
      line-height: 42px;
    }
    .content2 {
      font-size: 14px;
      font-weight: 400;
      color: #31363E;
      line-height: 24px;
    }
    .content3 {
      background: #E7EEF7;
      border-radius: 4px;
      padding: 8px;
      font-size: 14px;
      font-weight: 700;
      color: #31363E;
    }
  }
}

.select-item {
  margin-left: 10px;
}
</style>
