//系统设置相关
import request from "@/utils/request";

export const getSetting = () =>
  request({
    url: "/setting/getSetting",
    method: "GET",
  });
//设置和重置安全码
export const settingResetSecurityCode = (data) =>
  request({
    url: "/user/setting/resetSecurityCode",
    method: "post",
    data,
  });


// 绑定谷歌验证器
export const settingBindGoogleAuth = (data) =>
  request({
    url: "/user/setting/bindGoogleAuth",
    method: "post",
    data,
  });

  // 修改谷歌验证器

export const settingUpdateGoogleAuth = (data) =>
request({
  url: "/user/setting/updateGoogleAuth",
  method: "post",
  data,
});

//重置手机号
export const settingResetPhone = (data) =>
request({
  url: "/user/setting/resetPhone",
  method: "post",
  data,
});
//重置邮箱
export const settingResetEmail = (data) =>
request({
  url: "/user/setting/resetEmail",
  method: "post",
  data,
});

///加密账户余额预估
export const getBalanceCoinName = (coinName) =>
  request({
    url: `account/getBalance/${coinName}`,
    method: "GET",
  });

  ///加密货币账号
export const cryptoCoinList = (data) =>
request({
  url: "/user/setting/cryptoCoinList",
  method: "post",
  data,
});

//谷歌验证码密钥/
export const getGoogleAuth = () =>
  request({
    url: "/user/setting/getGoogleAuth",
    method: "GET",
  });
//设置-开启或关闭： 邮箱验证，手机验证，安全码验证
export const openValid = (data) =>
request({
  url: "/user/setting/openValid",
  method: "post",
  data,
});

